/* Importing Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Reddit+Sans:ital,wght@0,200..900;1,200..900&display=swap');

body {
    background-color: white;
    font-family: 'Reddit Sans', sans-serif;
    font-size: 14px;
    color: black;
    margin: 0;
    padding: 0;
}

.fsc-navbar
{
    z-index: 9999;
}

.contact-banner
{
    height: 75vh;
}

.contact-icon
{
    height: 3rem;
    width: 3.3rem;
}

    /* ! Carousel */
.h-carousel {
    width: 100vw;
    height: 80vh;
    overflow: hidden;
    background-color: black;
    color: white;
    position: relative;
}

.h-carousel-description {
    font-size: 1.2rem;
}

.h-carousel-item {
    position: absolute;
    inset: 0 0 0 0;
}


    .h-carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.h-carousel-content{
    position: absolute;
    top: 25%;
    max-width: 90%;
    left: 40%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: white;
    text-shadow: 0 5px 10px black;
}


.h-carousel-author {
    font-weight: bold;
    letter-spacing: 10px;
    font-size: 1.8rem;
}

.h-carousel-title, .h-carousel-topic {
    font-weight: bold;
    font-size: 3.1rem;
    line-height: 1.3em;
}

.h-carousel-topic {
    color: #fd2e2e;
}

.h-carousel-buttons {
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
    font-size: 1rem;
}

.h-carousel-buttons button {
    background-color: white;
    color: black;
    letter-spacing: 1px;
    font-weight: 500;
    width: 10rem;
    height: 3rem;
    transition:500ms;
}

.h-carousel-buttons button:hover
{
    width: 10.5rem;
    height: 3.5rem;
    background-color: black;
    color: white;
}


.h-carousel-item .image-wrapper {
    width: 100%;
    height: 100%;
    position: relative; /* Ensure positioning context for pseudo-element */
}

.h-carousel-item .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.h-carousel-item .image-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Black with 20% opacity */
    z-index: 0; /* Ensure overlay is above the image */
}


/*! Thumbnail */

.h-carousel-thumbnail {
    position: absolute;
    bottom: 150px;
    left: 50%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
}

.h-carousel-thumbnail-item {
    width: 150px;
    height: 220px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    transition: 500ms;
}


.h-carousel-thumbnail-item:hover {
    width: 170px;
    height: 240px;
    transition: .5s ease-in-out;
    box-shadow: 1px 1px 10px black;
}

.h-carousel-thumbnail-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

.h-carousel-thumbnail-content {
    position: absolute;
    bottom: 30px;
    left: 10px;
    right: 10px;
}

.h-carousel-thumbnail-title {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    text-shadow: 0 5px 10px black;
}

.h-carousel-thumbnail-item {
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
    white-space: nowrap; /* Prevent text from wrapping */
}

/* ! arrow */

.arrows {
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-content: center;
    margin-top: 40rem;
    margin-left: 10%;
}

.arrows button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 26, 26, 0.45);
    border: none;
    font-weight: bold;
    font-size: large;
    z-index: 100;
    transition: 500ms;
}

.arrows button:hover {
    width: 42px;
    height: 42px;
    background-color: #eee;
    color: #555;
    transition: 1s ease;
}

.h-carousel .h-carousel-item:nth-child(1) {
    z-index: 1;
}

/*Text fade out in effect*/

.h-carousel-item:nth-child(1) .h-carousel-author,
.h-carousel-item:nth-child(1) .h-carousel-title,
.h-carousel-item:nth-child(1) .h-carousel-topic,
.h-carousel-item:nth-child(1) .h-carousel-description,
.h-carousel-item:nth-child(1) .h-carousel-buttons {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showCarouselContent 0.5s 1s linear 1 forwards;
}

@keyframes showCarouselContent {
    to {
        opacity: 1;
        filter: blur(0);
        transform: translateY(0);
    }
}

.h-carousel-item:nth-child(1) .h-carousel-author {
    animation-delay: 0.4s;
}

.h-carousel-item:nth-child(1) .h-carousel-title {
    animation-delay: 0.4s;
}

.h-carousel-item:nth-child(1) .h-carousel-topic {
    animation-delay: 0.6s;
}

.h-carousel-item:nth-child(1) .h-carousel-description {
    animation-delay: 1s;
}

.h-carousel-item:nth-child(1) .h-carousel-buttons {
    animation-delay: 1.4s;
}

.next-carousel .h-carousel-item:nth-child(1) img {
    width: 150px;
    height: 220px;
    position: absolute;
    left: 55%;
    bottom: 150px;
    border-radius: 20px;
    animation: expandThumbnailImageEffect 0.3s linear 1 forwards;
}

@keyframes expandThumbnailImageEffect {
    to {
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        border-radius: 0;
    }
}

.next-carousel .h-carousel-thumbnail .h-carousel-thumbnail-item:nth-last-child(1) {
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}

@keyframes showThumbnail {
    from {
        width: 0;
        opacity: 0;
    }
    to {
        width: 150px;
        opacity: 1;
    }
}

.next-carousel .h-carousel-thumbnail {
    /*Move group of 4 thumbnails to the beginning of where the last one is*/
    transform: translateX(150px);
    animation: moveThumbnailRight .5s linear 1 forwards;
}

@keyframes moveThumbnailRight {
    to {
        /*grow to the original position of what the thumbnail was*/
        transform: translateX(0);
    }
}

/*Previous animation effect*/

.prev-carousel .h-carousel-item:nth-child(2) {
    /*After we move the item we will make it so the image will move below the first thumbnail img*/
    z-index: 2;
}

.prev-carousel .h-carousel-item:nth-child(2) img {
    /*move full image to position of img*/
    position: absolute;
    bottom: 0;
    left: 0;
    animation: zoomOutBackgroundImage 0.5s linear 1 forwards;
}

@keyframes zoomOutBackgroundImage {
    to {
        width: 150px;
        height: 220px;
        border-radius: 20px;
        left: 50%;
        bottom: 150px;
        visibility: hidden;
    }

}

.prev-carousel .h-carousel-thumbnail .h-carousel-thumbnail-item:nth-child(1) {
    width: 0;
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail 0.5s linear 1 forwards;
}

.prev-carousel .h-carousel-item:nth-child(2) .h-carousel-author,
.prev-carousel .h-carousel-item:nth-child(2) .h-carousel-title,
.prev-carousel .h-carousel-item:nth-child(2) .h-carousel-topic,
.prev-carousel .h-carousel-item:nth-child(2) .h-carousel-description,
.prev-carousel .h-carousel-item:nth-child(2) .h-carousel-buttons {

    animation: contentOut 0.5s 0.5s linear 1 forwards;
}

@keyframes contentOut {
    to {
        transform: translateY(-150px);
        animation: transformThumbnail 0.5s linear 1 forwards;
        opacity: 0;
    }
}

/*Coach Section*/

.coach-email {
    color: #007bff; /* Link color */
    text-decoration: underline; /* Underline link */
    cursor: pointer; /* Pointer cursor on hover */
    margin-top: 0.5rem;
}

.coach-description,
.coach-email {
    width: 0;
    height: 0;
    opacity: 0;
    transform: scale(0.9); /* Start slightly smaller */
    visibility: hidden; /* Hide elements initially */
    overflow: hidden; /* Ensure no content overflows */
    transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease, transform 0.5s ease, visibility 0.5s ease;
    /* Ensure the element does not affect layout initially */
}

.coach-card {
    cursor: pointer;
    position: relative; /* Ensure absolutely positioned children are relative to this element */
}

/* Show elements on hover */
.coach-card:hover .coach-description,
.coach-card:hover .coach-email {
    width: auto; /* Transition to natural width */
    height: auto; /* Transition to natural height */
    opacity: 1;
    transform: scale(1); /* Grow to normal size */
    visibility: visible; /* Make elements visible */
}


/* Calendar */

.GoogleSchedule {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.responsive-iframe-container {
    position: relative;
    width: 100%;
    max-width: 1200px; /* Set your desired max width */
    padding-bottom: 56.25%; /* Aspect ratio 16:9 */
    height: 0;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}

.responsive-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.banner
{
    z-index: 100;
}

@media screen and (max-width: 678px) {

    .h-carousel-content
    {
        text-align: center;
    }

    .h-carousel-buttons
    {
        display: flex;
        justify-content: center;
    }


        .h-carousel-thumbnail {
        display: none;
    }
        
    .h-carousel .h-carousel-content {
        margin-left: 4rem;
        width: 100%;
        padding-right: 2rem;

    }
    
    .h-carousel .h-carousel-author
    {
        font-size: 1rem;
    }
    

    .h-carousel .h-carousel-title, .h-carousel .h-carousel-topic {
        font-size: 2.5rem;

    }

    .h-carousel-description {
        font-size: 1em;
    }
    

    .h-carousel {
        height: 30rem;
    }
}

.card-flex img:nth-child(1) {
    height: 230px;
}

@media (min-width: 600px) and (max-width: 1350px) {
    .max-width{
        width:100%;
        display:flex;
        justify-content:center;
    }

    .arrows {
        display: none;
    }

    .h-carousel-content
    {
        text-align: center;
    }

    .h-carousel-buttons
    {
        display: flex;
        justify-content: center;
    }
    

    .h-carousel-thumbnail {
        display: none;
    }
   
    
    .h-carousel-content {
        top: 25%;
        padding-right: 0;
    }
    
    .h-carousel-topic, .h-carousel-title {
        font-size: 3rem;
    }
    
    .h-carousel-author
    {
        font-size: 1rem;
    }
    
}




















